import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../modules/shared/components/layout"
import { Link } from "gatsby-plugin-react-i18next"
import { useTranslation } from "react-i18next"
import './404.scss'

// markup
const NotFoundPage = () => {
  const { t } = useTranslation()


  const metas = {
    title: t("seo.404.title"),
    description: t("seo.404.description")
  }

  const pageContext = {
    lang: { key: 'es', locale: 'es-ES' },
    route: {
      id: '404',
      slugs: {
        es: '/',
        eu: '/'
      }
    },
    default: 'es',
    langs: [
      { key: 'es', locale: 'es-ES' },
      { key: 'eu', locale: 'eu' }
    ]
  }

  return (
    <Layout
      metas={metas}
      pageContext={pageContext}
    >
      <div className="wrapper-404-page wrapper-fluid">
        <div className="content">
          <h1>{t("notFoundPage.title")}</h1>
          <Link to="/" className="btn stroked">{t("notFoundPage.linkTitle")}</Link>
        </div>
      </div>

    </Layout >
  )
}

export default NotFoundPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;